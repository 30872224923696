export const routes = {
  INDEX: {
    path: "/",
  },
  REPORTS: {
    path: "/reports",
  },
  SETTINGS: {
    path: "/reports/:report_id/settings",
  },
  ACCOUNT: {
    path: "/settings/account",
  },
  REPORT_USERS: {
    path: "/reports/:report_id/settings/users",
  },
  USERS: {
    path: "/settings/users",
  },
  REPORT_USERS_CREATE: {
    path: "/reports/:report_id/settings/users/new",
  },
  USERS_CREATE: {
    path: "/settings/users/new",
  },
  REPORT_USERS_EDIT: {
    path: "/reports/:report_id/settings/users/:user_id",
  },
  USERS_EDIT: {
    path: "/settings/users/:user_id",
  },
  REPORTS_ACCOUNT: {
    path: "/reports/:report_id/settings/account",
  },
  CHECKLIST: {
    path: "/reports/:report_id/checklist",
  },
  CHECKLIST_NEW_GROUP: {
    path: "/reports/:report_id/checklist/tasks-groups/new-group",
  },
  CHECKLIST_TASKS_GROUP: {
    path: "/reports/:report_id/checklist/tasks-groups/:tasks-groups_id",
  },
  CHECKLIST_NEW_TASKS: {
    path: "/reports/:report_id/checklist/:tasks-groups_id/tasks/new-task",
  },
  DOCUMENTS: {
    path: "/reports/:report_id/documents",
  },
  INFORMATION: {
    path: "/reports/:report_id/information",
  },
  HISTORY: {
    path: "/reports/:report_id/history",
  },
  REPORTS_LIST: {
    path: "/reports",
  },
  SIGN_IN: {
    path: "/sign-in",
  },
  TASK: {
    path: "/reports/:report_id/checklist/:tasks-groups_id/tasks/:task-id",
  },
} as const
