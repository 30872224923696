import {
  VariantColorsResolver,
  defaultVariantColorsResolver,
  parseThemeColor,
  Button as MantineButton,
  createTheme,
  ButtonProps,
  MantineThemeOverride,
} from "@mantine/core"
import { Link } from "@remix-run/react"
import { forwardRef } from "react"

import classes from "./Button.module.css"

export const ButtonVariantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  if (parsedColor.isThemeColor) {
    if (input.variant === "filled") {
      return {
        background: "var(--mantine-color-kiosk-green-0)",
        hover: "var(--mantine-color-green-7)",
        color: "var(--mantine-color-white)",
        border: "none",
      }
    }
    if (input.variant === "outline") {
      return {
        background: "var(--mantine-color-white)",
        hover: "var(--mantine-color-gray-1)",
        color: "var(--mantine-color-gray-8)",
        border: "1px solid var(--mantine-color-gray-3)",
      }
    }
  }

  if (input.variant === "outline-green") {
    return {
      background: "var(--mantine-color-green-0)",
      hover: "var(--mantine-color-green-1)",
      color: "var(--mantine-color-gray-8)",
      border: "1px solid var(--mantine-color-kiosk-green-0)",
    }
  }

  if (input.variant === "gray") {
    return {
      background: "var(--mantine-color-gray-1)",
      hover: "var(--mantine-color-gray-2)",
      color: "var(--mantine-color-gray-8)",
      border: "none",
    }
  }

  if (input.variant === "destructive") {
    return {
      background: "var(--mantine-color-white)",
      hover: "var(--mantine-color-gray-0)",
      color: "var(--mantine-color-red-8)",
      border: "1px solid var(--mantine-color-red-3)",
    }
  }

  if (input.variant === "icon") {
    return {
      background: "transparant",
      hover: "var(--mantine-color-gray-0)",
      color: "var(--mantine-color-gray-5)",
      border: "none",
    }
  }

  return defaultResolvedColors
}

export const themeButton: MantineThemeOverride = createTheme({
  components: {
    Button: {
      defaultProps: { size: "md" },
      classNames: { root: classes.button__root, label: classes.button__label },
    },
  },
})

type Props = {
  variant?:
    | "filled"
    | "outline"
    | "gray"
    | "destructive"
    | "icon"
    | "outline-green"
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: "button" | "submit" | "reset"
  form?: string
  link?: string
  download?: boolean
} & ButtonProps

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = "filled",
      onClick,
      type = "button",
      form,
      link,
      download,
      ...rest
    }: Props,
    ref,
  ) => {
    const disabledByVariantClass = {
      filled: classes["button__disabled--filled"],
      outline: classes["button__disabled--outline"],
      gray: classes["button__disabled--gray"],
      destructive: classes["button__disabled--destructive"],
      icon: classes["button__disabled--icon"],
      "outline-green": classes["button__disabled--outline"],
    }

    const disabledClass = rest.disabled
      ? `${classes.button__disabled} ${disabledByVariantClass[variant]}`
      : undefined
    const iconVariant = variant === "icon" ? classes.button__icon : undefined
    const filledVariantClass =
      variant === "filled" ? classes["button__filled-icon"] : undefined
    const rootClassName = [disabledClass, iconVariant, rest.className]
      .filter((c) => c !== undefined)
      .join(" ")

    if (link) {
      return (
        <MantineButton
          to={link}
          component={Link}
          variant={variant}
          type={type}
          {...rest}
          classNames={{ root: rootClassName, section: filledVariantClass }}
          download={download}
          target={download ? "_blank" : undefined}
        >
          {rest.children}
        </MantineButton>
      )
    }

    return (
      <MantineButton
        ref={ref}
        variant={variant}
        type={type}
        form={form}
        {...rest}
        classNames={{ root: rootClassName, section: filledVariantClass }}
        onClick={onClick}
      >
        {rest.children}
      </MantineButton>
    )
  },
)

Button.displayName = "Button"
