import {
  createTheme,
  MantineThemeOverride,
  rem,
  Select,
  ThemeIcon,
} from "@mantine/core"
import { IconChevronDown } from "@tabler/icons-react"

export const themeSelect: MantineThemeOverride = createTheme({
  components: {
    Select: {
      defaultProps: {
        checkIconPosition: "right",
        rightSection: (
          <ThemeIcon>
            <IconChevronDown />
          </ThemeIcon>
        ),
      },
      styles: {
        input: {
          height: 42,
          borderColor: "var(--mantine-color-gray-2)",
        },
        label: {
          fontSize: rem(14),
          fontWeight: 500,
          marginBottom: rem(14),
        },
      },
    },
  },
})

export const SelectKiosk = ({
  ...rest
}: React.ComponentProps<typeof Select>) => {
  return <Select {...rest} />
}
